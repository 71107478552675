/*html的字体为屏幕宽的7.5分之一*/
html {
	font-size: 13.33333vw;
}
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
span,
section,
a,
i,
b,
label {
	margin: 0;
	padding: 0;
	line-height: 1;
	font-weight: 400;
}
body * {
	-webkit-text-size-adjust: none;//文本大小不会根据设备尺寸进行调整
}
body {
	margin: 0;
	padding: 0;
	color: #54534a;
	background: #fff;
	font-size: .3rem;
}
img {
	max-width: 100%;
	height: auto;
}
a,
a:hover,
a:link {
	text-decoration: none;
	color: #333;
	outline-style: none;
	-moz-outline-style: none;
}
li {
	list-style: none;
}
em,
i,
label {
	font-style: normal;
	font-weight: normal;
}
a,
input,
button {
	border: none;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	padding: 0;
}
textarea {
	resize: vertical;
	border: 0;
}
table {
	border-collapse: collapse;
}
textarea,input{
	&::-webkit-input-placeholder {
		color: #c0c4cc;
		font-size: .3rem;
	}
	&::-moz-placeholder {
		color: #c0c4cc;
		font-size: .3rem;
	}
	&::-moz-placeholder {
		color: #c0c4cc;
		font-size: .3rem;
	}
	&:-ms-input-placeholder {
		color: #c0c4cc;
		font-size: .3rem;
	}
}

dl {
	margin-bottom: 0;
}
.clearfix:after{
	display:block;
	height:0;
	line-height:0;
	content:"";
	visibility:hidden;
	clear:both;
}
.clearfix{zoom:1;}
.fl{ float: left;}
.fr{ float: right;}
.no-padding {
	padding: 0;
}
.no-margin {
	margin: 0;
}

/*文字超出省略号*/
.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-icab-text-overflow: ellipsis;
	-khtml-text-overflow: ellipsis;
	-moz-text-overflow: ellipsis;
	-webkit-text-overflow: ellipsis;
}
/*处理遮罩下面的元素无法触发点击事件*/
.pointer-events {
	pointer-events: none;
}
/*兼容IE,未知高度文字垂直居中*/
.vertical-box {
	display: table;
	height: 100%;
	/*必需给最外层设置高度*/
	*position: relative;
	/*针对IE的hack*/
}
.vertical-box .vertical-box-sub {
	display: table-cell;
	vertical-align: middle;
	*position: absolute;
	/*针对IE的hack*/
	*top: 50%;
}
.vertical-box .vertical-box-container {
	*position: relative;
	/*针对IE的hack*/
	*top: -50%;
	margin: 0 auto;
}
/* Let's get this party started */
.scroll-style::-webkit-scrollbar {
	width: 8px;
	background-color: #fff;
}
/* Track */
.scroll-style::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 8px;
	border-radius: 8px;
}
/* Handle */
.scroll-style::-webkit-scrollbar-thumb {
	-webkit-border-radius: 8px;
	border-radius: 8px;
	background: #ccd5df;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.scroll-style::-webkit-scrollbar-thumb:window-inactive {
	background: #ccd5df;
}


//弹性盒模型
// .flex(unknown) {
//   display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
//   display: -moz-box; /* Firefox 17- */
//   display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
//   display: -moz-flex; /* Firefox 18+ */
//   display: -ms-flexbox; /* IE 10 */
//   display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
// }

// // 居中, 不确定尺寸, 不兼容 IE6
// .center(unknown) {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
// }

// //移位
// .translate(@x:0,@y:0) {
//   transform: translate(@x, @y);
//   -ms-transform: translate(@x, @y); /* IE 9 */
//   -webkit-transform: translate(@x, @y); /* Safari and Chrome */
//   -moz-transform: translate(@x, @y);
// }

// //缩放
// .scale(@v:0) {
//   transform: scale(@v);
//   -ms-transform: scale(@v); /* IE 9 */
//   -webkit-transform: scale(@v); /* Safari and Chrome */
//   -moz-transform: scale(@v);
// }

// //旋转
// .rotate(@v:0) {
//   transform: rotate(@v);
//   -ms-transform: rotate(@v); /* IE 9 */
//   -webkit-transform: rotate(@v); /* Safari and Chrome */
//   -moz-transform: rotate(@v);
// }

// //图片变模糊
// .picVague(@v:10px) {
//   -webkit-filter: blur(@v); /* Chrome, Opera */
//   -moz-filter: blur(@v);
//   -ms-filter: blur(@v);
//   filter: blur(@v);
// }
