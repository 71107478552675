$orange: #d7ae42;
$black: #000;
$font14: 0.14rem;
$font28: 0.28rem;
$font38: 0.38rem;
$font48: 0.48rem;
h3 {
	font-size: $font38;
}
.describe {
	padding: 0.26rem 0;
	line-height: 1.5;
	font-size: $font14;
}
.introduce {
	border: 0.04rem solid $orange;
}
.border-horn {
	display: block;
	position: absolute;
	width: 0.6rem;
	height: 0.6rem;
	transform: rotate(30deg);
	background-color: #fff;
}
.forms-button {
	margin: 0;
	margin-top: 1rem;

	text-align: center;
	> a {
		padding: 0 1rem;
		color: $orange;
		&::before {
			border-color: $orange !important;
		}
	}
	a,
	span {
		color: $orange;
	}
}
// 首页
.home {
	height: 100vh;
	.swiper-container {
		height: 100vh;
		.swiper-slide{ 
			box-sizing: border-box;
			 overflow: hidden;
			 }
	}

	.music {
		box-sizing: border-box;
		position: absolute;
		right: .12rem;
		top: .2rem;
		width: .6rem;
		height: .6rem;
		line-height: .66rem;
		border: .02rem solid #999;
		text-align: center;
		border-radius: .5rem;
		z-index: 9;
		background-color: #fff;
		img{
			width: .3rem;
			height: .3rem;
		}
	}

	.view1 {
		.border-horn {
			right: -0.21rem;
			top: -0.45rem;
			border-bottom: 0.04rem solid $orange;
		}

		.introduce {
			position: relative;
			box-sizing: border-box;
			width: 6.5rem;
			padding: 0.4rem 0.3rem 0.1rem 0.5rem;
			margin: 0.7rem 0;
			border-left: none;
			img {
				width: 3.5rem;
			}
			span {
				display: block;
				padding: 0.2rem 0;
				color: $orange;
				font-size: $font14;
			}
			h3 {
				color: $black;
			}
		}
		.experience {
			box-sizing: border-box;
			position: absolute;
			width: 100%;
			left: 0;
			bottom: .3rem;
			animation-fill-mode: both !important;
			padding: 0.3rem;
			.fl {
				width: 2.5rem;
				padding-top: 1rem;
				img {
					display: block;
					margin-top: 0.12rem;
				}
			}
			.fr {
				width: 4.3rem;
			}
		}
	}
	.view2 {
		.pic{
			width: 100%;
			height:70vh;
			background: url(../img/hall1.png) no-repeat center bottom;
			background-size: cover;
			
			
		}
		.introduce {
			box-sizing: border-box;
			width: 6rem;
			padding: 0.5rem 0.4rem 0.5rem 0.7rem;
			border-right: 0;
			transform: translate(25%, -30%);
			background: rgba(255, 255, 255, 0.8);
			h3,
			span {
				color: $orange;
			}
			h3 {
				margin-bottom: 0.26rem;
				font-size: $font48;
			}
			span {
				opacity: 0.8;
			}
			.describe {
				line-height: 2;
			}

			.border-horn {
				left: -0.22rem;
				bottom: -0.46rem;
				border-top: 0.04rem solid $orange;
			}
		}
	}

	.hall-box {
		padding-top: .1rem;
		background: url(../img/bg.png) repeat;
		background-size: 3%;
		.first-floor {
			box-sizing: border-box;
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
			height: 0.7rem;
			padding: 0 0.5rem;
			background: url(../img/bg2.png) no-repeat;
			background-size: cover;
			color: $orange;
			font-size: 0.42rem;
			p {
				transform: translate(0, -.7rem);
				line-height: 1.4;
			}
		}
		ul {
			padding: 0 .5rem;

			li {
				display: flex;
				padding: .25rem 0;

				> div {
					width: 50%;
					justify-content: space-between;
					margin: auto;

					&.pic {
						img {
							box-shadow: -0.1rem -0.1rem 0 $orange;
						}
					}
					&.title {
						p {
							display: inline-block;
							margin-left: 0.5rem;
						}
						p:nth-child(1) {
							padding-bottom: 0.1rem;
							border-bottom: $orange solid 0.02rem;
							font-size: 0.36rem;
						}
						p:nth-child(2) {
							padding: 0.15rem 0;
							opacity: 0.5;
							font-size: $font28;
							line-height: 1.3;
						}
					}
				}
			}
		}
	}
	.view3 {
	
		
		ul {
			li {
				&:nth-child(even) {
					animation-fill-mode: both !important;
					.pic {
						transform: translateX(100%);
						img {
							box-shadow: 0.1rem -0.1rem 0 $orange;
						}
					}
					.title {
						transform: translateX(-100%);
					}
				}
			}
		}
		.first-floor {
			animation-fill-mode: both !important;
		}
	}
	.view4 {
			//display: block !important;
		.first-floor {
			p {
				text-align: right;
			}
		}
		ul {
			li {
				opacity: 0 !important;
				.title{p{ font-size: .32rem!important;}}
				&:nth-child(odd) {
					.pic {
						transform: translateX(100%);
						img {
							box-shadow: 0.1rem -0.1rem 0 $orange;
						}
					}
					.title {
						
						transform: translateX(-100%);
					}
				}
			}
		}
	}
	.view5 {
		background: url(../img/bg.png) repeat;
		background-size: 3%;
		header {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1rem;
			padding: 0.35rem;
			background-color: #fff;
			img {
				width: 3rem;
			}
		}
		.content {
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			min-height: 100vh;
			padding-top: 1.6rem;
			.main {
				position: relative;
				display: flex;
				flex: 1;
				.border-bg {
					position: absolute;
					left: 0;
					width: 100%;
					height: 0.8rem;
					background: url(../img/bg2.png) no-repeat;
					background-size: cover;
					&:nth-child(1) {
						top: 0;
					}
					&:nth-child(2) {
						bottom: 0;
					}
				}
				dl {
					margin: auto;
					text-align: center;
					dt {
						p {
							line-height: 2.5;
							&:nth-child(1) {
								font-size: $font38;
							}
							&:nth-child(2) {
								font-size: 0.6rem;
							}
							&:nth-child(3) {
								color: $orange;
								font-size: $font14;
							}
						}
					}
				}
			}
			footer {
				padding: 0.6rem 0;
				text-align: center;
				img {
					width: 3rem;
					margin-bottom: 0.4rem;
				}
			}
		}
	}
}

//表单
.forms {
	padding: 0 0.3rem;
	input,
	.am-textarea-control textarea {
		ont-size: 0.3rem;
		color: #666 !important;
	}
	.am-list-header {
		padding-bottom: 0;
		padding-left: 0;
		color: #333;
		font-size: 0.35rem;
	}
	.am-list-body {
		&::before {
			background: none !important;
		}
	}
	.am-list-item {
		padding-left: 0 !important;
		.am-list-line .am-list-extra {
			flex-basis: initial;
			font-size: 0.3rem;
			text-align: left;
			color: #666;
		}
	}

	.forms-button {
		margin: 0.8rem 0;
	}
}

//滑屏动画效果
.swiper-slide-active {
	&.view1 {
		.introduce {
			animation: flipinY 1.5s forwards;
		}
		.describe {
			animation: shakeY 20s 2s forwards infinite;
		}
		.experience {
			animation: fadeinB 1s 0.5s forwards;
		}
	}
	&.view2 {
		.pic {
			animation: rotatein 1s forwards;
		}
		.describe {
			animation: shakeY 20s 1s forwards infinite;
		}
	}
	&.view3 {
		ul {
			li {
				&:nth-child(odd) {
					animation: bouncein 0.5s forwards;
				}
				&:nth-child(even) {
					animation: bouncein 0.5s 0.5s forwards;
				}
			}
		}
		.first-floor {
			animation: bounceinT 0.8s 1s;
		}
	}
	&.view4 {
		.first-floor {
			animation: ring 0.5s forwards;
		}

		ul {
			li {
				&:nth-child(4) {
					animation: fadeinT 0.2s 0.8s forwards;
					opacity: 1;
				}
				&:nth-child(3) {
					animation: fadeinT 0.3s 1s forwards;
					opacity: 1;
				}
				&:nth-child(2) {
					animation: fadeinT 0.4s 1.3s forwards;
					opacity: 1;
				}

				&:nth-child(1) {
					animation: fadeinT 0.5s 1.5s forwards;
					opacity: 1;
				}
			}
		}
	}
	&.view5 {
		dl {
			animation: bouncein 1s forwards;
		}
		.forms-button {
			animation: shakeY 8s 1s forwards infinite;
		}
	}
}

//音乐播放
.musicPlay{
	animation: rotate2d 1.2s linear infinite
}

//预约成功
.order{
	.am-icon-md{
	  width: 50px;
	  height: 50px;
	}
	
	.am-icon{
	  fill: $orange !important;
	}
	.am-result-title{
		color: $orange !important;
	}
	
	.am-list-header{ padding: 0;}
	.am-list-item {
		.am-input-control input:disabled{
			color: #333;
		}
		 .fake-input{ color: #333 !important;
		  line-height: 1.8 !important;}
	}
	
	.am-textarea-control textarea:disabled{
		color: #333 !important;
		 line-height: 1.3 !important;
		 text-align: right;
	}
	
	.am-list-body::before,{ background:none!important;}
	
	.am-list-item .am-input-label,
	.am-textarea-label{ font-size: 14px;}
}